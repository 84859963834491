<template>
  <section class="content px-4 py-2 my-2" name="MassiveOrders">
    <v-layout wrap>
      <v-flex xs12>
        <AppTitle title="Mensaje Predefinido" subtitle="---" />
      </v-flex>
      <v-flex xs12>
        <v-data-table
          :headers="headers"
          :items="items"
          class="elevation-1 order-table"
          color="secondary"
          :disable-initial-sort="true"
          :pagination.sync="pagination"
          :rows-per-page-items="rowsPerPage"
          no-data-text="No hay mensajes predefinidos ingresados"
        >
          <template slot="items" slot-scope="props">
            <tr>
              <td v-html="props.item.name"></td>
              <td v-html="props.item.words"></td>
              <td>
                <v-icon small class="mr-2" @click="editItem(props.item)">
                  edit
                </v-icon>
                <v-icon small class="mr-2" @click="deleteAction(props.item.id)">
                  delete
                </v-icon>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-btn fab dark fixed bottom right color="success" @click="newItem">
      <v-icon>add</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" width="800">
      <v-card>
        <AppTitleDialog
          :title="'Agregar pagina'"
          :close-action="
            () => {
              dialog = false
            }
          "
          close
        />
        <v-card-text class="pa-0">
          <v-container grid-list-lg>
            <v-layout wrap>
              <v-flex xs4>
                <v-text-field v-model="form.name" hide-details label="Nombre" />
              </v-flex>
              <v-flex xs8>
                <v-text-field
                  v-model="form.words"
                  hide-details
                  label="Palabras claves"
                />
              </v-flex>
              <v-flex xs12>
                <v-textarea
                  v-model="form.body"
                  label="Mensaje predefinido"
                  hint="Se pueden usar las siguientes variables: {name} {code}"
                ></v-textarea>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn flat color="back" depressed @click="dialog = false">
            Volver
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn flat color="accent" depressed @click="save"> Listo </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { WSP_MSJ } from '../../config'
import AppTitle from '../useful/title.vue'
import AppTitleDialog from '../useful/titleDialog.vue'

export default {
  name: 'SettingsMsjWsp',
  components: {
    AppTitle,
    AppTitleDialog
  },
  data() {
    return {
      items: [],
      dialog: false,
      form: {
        name: '',
        words: '',
        body: ''
      },
      headers: [
        { text: 'Nombre', align: 'left', sortable: false },
        { text: 'Palabras', align: 'left', sortable: false },
        { text: '', align: 'left', sortable: false }
      ],
      pagination: { rowsPerPage: 50 },
      rowsPerPage: [25, 50, 100, 200, { text: 'Todos', value: -1 }]
    }
  },
  mounted() {
    this.getMsjWsp()
  },
  methods: {
    async save() {
      try {
        await this.$http({
          method: this.form.id ? 'put' : 'post',
          url: this.form.id ? `${WSP_MSJ}/${this.form.id}` : WSP_MSJ,
          data: {
            p: 'saveWspMsj',
            form: this.form
          }
        })
        this.dialog = false
        this.getMsjWsp()
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async delete(id) {
      try {
        await this.$http.delete(`${WSP_MSJ}/${id}`)
        this.$store.dispatch('setConfirm', { active: false })
        this.getMsjWsp()
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async getMsjWsp() {
      const res = await this.$http.get(WSP_MSJ)
      this.items = res.data
    },
    deleteAction(value) {
      this.$store.dispatch('setConfirm', {
        active: true,
        title: '¿Eliminar mensaje?',
        description: '',
        action: () => this.delete(value)
      })
    },
    newItem() {
      this.dialog = !this.dialog
      this.form = {
        name: '',
        type: '',
        slug: '',
        list: '',
        url: '',
        description: '',
        status: true,
        category_id: 0,
        section_id: 0
      }
    },
    editItem(item) {
      this.form = item
      this.dialog = !this.dialog
    }
  }
}
</script>
